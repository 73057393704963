<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="优惠券信息">
        <icon-button slot="extra" icon="return" @click.native="goBack" />

        <el-descriptions :column="3">
          <el-descriptions-item>
            <template slot="label"> 优惠券编号</template>
            {{ useInfoData.couponId || "" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 优惠券类型</template>
            {{
              useInfoData.priceType == 0
                ? "全免"
                : useInfoData.priceType == 1
                  ? "面值"
                  : "折扣"
            }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 优惠券额度</template>
            {{ useInfoData.price }}元
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 领取账户</template>
            {{ useInfoData.userId }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 领取时间</template>
            {{ useInfoData.getTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 状态</template>
            {{
              useInfoData.isUse == 1
                ? "已使用"
                : "未使用"
            }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 发券操作人</template>
            {{ useInfoData.couponInfo.operator === 0 ? "用户" : useInfoData.couponInfo.operator === 1 ? "商户" : "平台" }}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 操作原因</template>
            {{ useInfoData.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 承担方</template>
            {{ useInfoData.undertaker }}
          </el-descriptions-item>

        </el-descriptions>
      </easy-card>

      <!-- <div class="separate-line"></div> -->
      <!-- 
      <easy-card title="事件规则">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 发行编号</template>
            {{ useInfoData.event.couponRuleCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 标题</template>
            {{ useInfoData.event.title }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 触发事件</template>
            {{ useInfoData.event.eventName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 承担方</template>
            {{ useInfoData.event.bearerName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 适用业务类型</template>
            {{ useInfoData.event.couponType === 0 ? "通用" : "停车券" }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 结算方</template>
            {{ useInfoData.event.paySettleAccountName }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card> -->

      <!-- <div class="separate-line"></div> -->

      <easy-card title="关联订单">
        <el-descriptions :column="3">
          <el-descriptions-item>
            <template slot="label"> 订单编号</template>
            {{ useInfoData.orderId }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 抵扣额</template>
            {{ (parseFloat(useInfoData.discountAmount)).toFixed(2) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 驶入时间</template>
            {{ useInfoData.driveInTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 使用账户</template>
            {{ useInfoData.phone }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 使用区域</template>
            {{ useInfoData.useRegionName }}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 驶出时间</template>
            {{ useInfoData.driveOutTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 使用车辆</template>
            <Numplate :type="useInfoData.plateColor">{{
              useInfoData.plateNumber
            }}
            </Numplate>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 使用车场</template>
            {{ useInfoData.parkingLotName }}
          </el-descriptions-item>


          <el-descriptions-item>
            <template slot="label"> 订单金额</template>
            {{ useInfoData.receivableAmount }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 应收金额</template>
            <span
                v-if="useInfoData.order.amountReceivable !== ''">{{ (parseFloat(useInfoData.order.amountReceivable)).toFixed(2) }}</span>
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 实收金额</template>
            {{ useInfoData.transactionAmount }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 使用时间</template>
            {{ useInfoData.order.useDate }}
          </el-descriptions-item> -->
        </el-descriptions>
      </easy-card>
    </div>
  </div>
</template>


<script>
import { getUseCouponInfo } from "@/api/coupon";
import Numplate from "@/components/Numplate";

export default {
  data() {
    return {
      useInfoData: {
        // couponInfo: {},
        // event: {},
        // order: {},
      },
    };
  },
  components: {
    Numplate,
  },
  methods: {
    //返回停车订单页面
    goBack() {
      this.$router.back();
    },
    //根据id查询使用详情
    getCouponUseInfo(id) {
      getUseCouponInfo({ id }).then((res) => {
        if (res && res.code === 30) {
          console.log(res.returnObject);

          this.useInfoData = res.returnObject;
          console.log(this.useInfoData);
        }
      });
    },
  },
  mounted() {
    const id = this.$route.query.id;
    this.getCouponUseInfo(id);
  },
};
</script>

<style lang="less" scoped>
.record-tilte {
  margin: 14px 0;
}
</style>
